<template>
  <div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Ambassadeurs récents
    </h2>
    <div
      v-if="recentAmbassadors"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Interview</th>
              <th class="px-4 py-3">Publié</th>
              <th class="px-4 py-3">Auteur</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(amb, index) in recentAmbassadors"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <td class="px-4 py-3 text-sm month-period">
                <router-link
                  :to="{ name: 'Interview', params: { id: amb.itw.id } }"
                  class="px-4 py-3 text-xs td-router-link"
                >
                  {{ formatMonth(amb.period) }}
                </router-link>
              </td>
              <td class="px-4 py-3 text-xs">
                <span
                  v-if="amb.itw && amb.itw.status === 1"
                  class="px-4 py-3 text-sm"
                >
                  En ligne
                </span>
                <span
                  v-else-if="amb.itw && amb.itw.status === 0"
                  class="px-4 py-3 text-sm"
                >
                  Brouillon
                </span>
                <span v-else class="px-4 py-3 text-sm">
                  Non créée
                </span>
              </td>
              <td class="px-4 py-3 text-xs">
                {{ amb.author_full_name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800"
      >
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Previous"
                  @click="changePagination(true)"
                >
                  <svg
                    aria-hidden="true"
                    class="w-4 h-4 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
              <li class="px-3 py-1 rounded-md">
                {{ recentAmbassadorsPage + 1 }}
              </li>
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Next"
                  @click="changePagination(false)"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Rang du mois actuel (top 100)
    </h2>
    <div
      v-if="currentMonthRank"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Utilisateur</th>
              <th class="px-4 py-3">Nombre de points</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(user, index) in currentMonthRank"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <td class="px-4 py-3">
                <div class="flex items-center text-sm">
                  <!-- Avatar with inset shadow -->
                  <div
                    class="relative hidden w-8 h-8 mr-3 rounded-full md:block"
                  >
                    <img
                      class="object-cover w-full h-full rounded-full"
                      :src="getProfilePicture(user.profile_picture)"
                      alt=""
                      loading="lazy"
                    />
                    <div
                      class="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    ></div>
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="text-xs text-gray-600 dark:text-gray-400">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3 text-sm">{{ user.amount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ambassador from "@/utils/api/ambassador";
import { formatMonth } from "@/utils/date";
import { getProfilePicture } from "@/utils/api/accounts";

export default {
  data() {
    return {
      recentAmbassadors: null,
      recentAmbassadorsPage: 0,
      currentMonthRank: null
    };
  },
  mounted() {
    this.fetchRecentAmbassadors();
    ambassador.currentMonthRank().then(res => {
      this.currentMonthRank = res.data;
    });
  },
  methods: {
    getProfilePicture: getProfilePicture,
    formatMonth: formatMonth,
    fetchRecentAmbassadors() {
      ambassador
        .recentAmbassadors(this.$data.recentAmbassadorsPage)
        .then(res => {
          this.$data.recentAmbassadors = res.data;
        });
    },
    changePagination(isLess) {
      // update page counter and call api
      if (isLess && this.$data.recentAmbassadorsPage !== 0) {
        this.$data.recentAmbassadorsPage--;
        this.fetchRecentAmbassadors();
      } else if (!isLess && this.$data.recentAmbassadors.length > 4) {
        this.$data.recentAmbassadorsPage++;
        this.fetchRecentAmbassadors();
      }
    }
  }
};
</script>
