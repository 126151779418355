<template>
  <div v-if="directory" class="presentation-block">
    <Modal :data="refuseButtonState" v-on:action="refusePublication" />
    <Modal :data="deleteButtonState" v-on:action="deleteDirectory" />
    <div class="flex flex-row justify-between items-center" id="user-preview">
      <div>
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          {{ directory.author.first_name }} {{ directory.author.last_name }}
        </h2>
        <h2 class="mb-6 text-sm font-semibold text-gray-700 dark:text-gray-200">
          @{{ directory.author.username }}
        </h2>
      </div>
      <img
        class="object-cover w-full h-full rounded-full"
        :src="getProfilePicture(directory.author.profile_picture)"
        alt=""
        loading="lazy"
      />
    </div>
    <div class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h3
        class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
      >
        Status de la publication
      </h3>
      <ul>
        <li>
          <p>Validé par un admin:</p>
          <span
            v-if="directory.admin_validated === 1"
            class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
          >
            Validé
          </span>
          <span
            v-else-if="directory.admin_validated === 0"
            class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:text-white dark:bg-orange-600"
          >
            En attente
          </span>
          <span
            v-else-if="directory.admin_validated === 2"
            class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
          >
            Refusé
          </span>
        </li>
      </ul>
      <h3
        class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
      >
        Données de la publication
      </h3>
      <ul>
        <li>
          <p>Titre:</p>
          <span>{{ directory.title }}</span>
        </li>
        <li>
          <p>Type:</p>
          <span>{{ directory.type.fr_content }}</span>
        </li>
        <li>
          <p style="flex: none">Description:</p>
          <span>{{ directory.description }}</span>
        </li>
        <li>
          <p>URL:</p>
          <a rel="noopener noreferrer" :href="directory.url" target="_blank">{{
            directory.url
          }}</a>
        </li>
        <li>
          <p>Prix:</p>
          <span>{{ directory.eur_price }} EUR</span>
        </li>
        <li>
          <p>Lieu:</p>
          <span>{{ directory.location }}</span>
        </li>
        <li>
          <p>Crée le:</p>
          <span>{{ dateFormat(directory.created_at) }}</span>
        </li>
      </ul>
      <div>
        <h3
          class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
        >
          Actions
        </h3>
        <ul v-if="directory.admin_validated === 0">
          <li>
            <button
              class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              @click="acceptPublication"
            >
              Valider
            </button>
            <button
              style="margin-left: 10px;"
              class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              @click="openRefusePublicationModal"
            >
              Refuser
            </button>
          </li>
        </ul>
        <button
          class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
          @click="openDeleteModal"
        >
          Supprimer cet élement
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import directory from "@/utils/api/directory";
import accounts from "@/utils/api/accounts";
import date from "@/utils/date";
import Modal from "@/components/Modal";

export default {
  components: { Modal },
  data() {
    return {
      directory: null,
      refuseButtonState: {
        open: false,
        title: "Refus de publication",
        textareaMessage:
          "Raison (optionnel, permet à l'utilisateur de savoir pour quelles raisons sa publication est refusée via un e-mail)"
      },
      deleteButtonState: {
        open: false,
        title: "",
        description: null
      }
    };
  },
  mounted() {
    // retrieve directories data from route props
    if (this.$route.params.directory) {
      this.$data.directory = this.$route.params.directory;

      // if doesn't exist, then call API
    } else {
      directory
        .getOne(this.$route.params.id)
        .then(res => {
          this.$data.directory = res.data;
        })
        .catch(() => this.$router.push({ name: "Directories" }));
    }
  },
  methods: {
    getProfilePicture: accounts.getProfilePicture,
    dateFormat: date.format,
    acceptPublication: function() {
      directory
        .publicationValidation(this.$data.directory.id, true, null)
        .then(() => {
          this.$data.directory.admin_validated = 1;
        });
    },
    openRefusePublicationModal: function() {
      this.refuseButtonState.open = true;
    },
    refusePublication: function(reason) {
      directory
        .publicationValidation(this.$data.directory.id, false, reason)
        .then(() => {
          this.$data.directory.admin_validated = 2;
          this.refuseButtonState.open = false;
        });
    },
    /**
     * Open modal for delete directory type.
     */
    openDeleteModal: function() {
      this.deleteButtonState.description = `Souhaitez-vous réellement supprimer "${this.$data.directory.title}" ?`;
      this.deleteButtonState.open = true;
    },
    deleteDirectory: function() {
      directory
        .deleteOne(this.$data.directory.id)
        .then(() => {
          this.$router.push({ name: "Directories" });
        })
        .catch(() => alert("Une erreur est survenue lors de la suppression."));
    }
  }
};
</script>
