<template>
  <div v-if="interview && interview.ambassador" class="presentation-block">
    <div class="flex flex-row justify-between items-center" id="user-preview">
      <div>
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          {{ interview.ambassador.first_name }}
          {{ interview.ambassador.last_name }}
        </h2>
        <h2 class="mb-6 text-sm font-semibold text-gray-700 dark:text-gray-200">
          @{{ interview.ambassador.username }}
        </h2>
      </div>
      <img
        class="object-cover w-full h-full rounded-full"
        :src="getProfilePicture(interview.ambassador.profile_picture)"
        alt=""
        loading="lazy"
      />
    </div>
    <div class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h3
        class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
      >
        Interview
      </h3>
      <ul>
        <li>
          <p>Titre:</p>
          <span v-if="!editMode" v-html="interview.title"></span>
          <input
            class="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-textarea focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
            v-else
            v-model="interview.title"
          />
        </li>
        <li>
          <p style="flex: none;">Contenu:</p>
          <span v-if="!editMode" v-html="interview.html_content"></span>
          <textarea
            class="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-textarea focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
            v-else
            v-model="interview.html_content"
          ></textarea>
        </li>
        <li>
          <p>Statut:</p>
          <span v-if="!editMode && interview.status === 1">En ligne</span>
          <span v-else-if="!editMode && interview.status === 0">Brouillon</span>
          <div v-else-if="editMode">
            <label>
              Brouillon
              <input type="radio" :value="0" v-model="interview.status" />
            </label>
            <label>
              En ligne
              <input type="radio" :value="1" v-model="interview.status" />
            </label>
          </div>
        </li>
        <li>
          <p>Crée le:</p>
          <span>{{ dateFormat(interview.created_at) }}</span>
        </li>
        <li>
          <p>Date de publication:</p>
          <span v-if="!editMode">{{
            dateFormat(interview.publication_date)
          }}</span>
          <datetime
            type="datetime"
            v-else-if="editMode"
            v-model="interview.publication_date"
          ></datetime>
        </li>
      </ul>
      <div>
        <h3
          class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
        >
          Actions
        </h3>
        <ul>
          <li>
            <button
              v-if="!editMode"
              class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              @click="switchMode"
            >
              Modifier
            </button>
            <button
              v-if="editMode"
              class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              @click="saveInterview"
            >
              Sauvegarder
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else>
    Aucun ambassadeur ce mois-ci
  </div>
</template>

<script>
import ambassador from "@/utils/api/ambassador";
import { format } from "@/utils/date";
import { getProfilePicture } from "@/utils/api/accounts";

export default {
  data() {
    return {
      interview: null,
      editMode: false
    };
  },
  mounted() {
    ambassador
      .getOne(this.$route.params.id)
      .then(res => {
        this.$data.interview = res.data;
      })
      .catch(() => this.$router.push({ name: "Ambassadors" }));
  },
  methods: {
    getProfilePicture: getProfilePicture,
    dateFormat: format,
    /**
     * Switch edit or view mode.
     */
    switchMode() {
      this.$data.editMode = !this.$data.editMode;
    },
    saveInterview() {
      ambassador
        .newOrUpdateInterview(
          this.$data.interview.id,
          this.$data.interview.title,
          this.$data.interview.html_content,
          this.$data.interview.status,
          this.$data.interview.publication_date
        )
        .then(() => {
          this.switchMode();
        });
    }
  }
};
</script>

<style lang="sass" scoped>
ul, li
  width: 100%
</style>
