import { axios } from "@/utils/api/index";

/**
 * Get current month rank of users.
 * @return {Promise<AxiosResponse<any>>}
 */
export const currentMonthRank = () => {
  return axios().get("/ambassador/current-month-rank");
};

/**
 * Get all recent ambassadors by 100.
 * @param page Current page.
 * @return {Promise<AxiosResponse<any>>}
 */
export const recentAmbassadors = page => {
  return axios().get("/ambassador/recent-ambassadors", {
    params: { page: page }
  });
};

/**
 * Get ambassador interview by ID.
 * @param id: Interview ID.
 * @return {Promise<AxiosResponse<any>>}
 */
export const getOne = id => {
  return axios().get(`/ambassador/interview/${id}`);
};

/**
 * Check if interview exists.
 * @param year_month: Year month of desired interview.
 * @return {Promise<AxiosResponse<any>>}
 */
export const isInterviewExists = year_month => {
  return axios().get(`/ambassador/is-interview-exists/${year_month}`);
};

/**
 * Post new or update interview.
 * @param id Interview ID.
 * @param title Interview title.
 * @param html_content Html content of interview.
 * @param status 0 = Draft, 1 = Published
 * @param publication_date Date of publication.
 * @return {Promise<AxiosResponse<any>>}
 */
export const newOrUpdateInterview = (
  id,
  title,
  html_content,
  status,
  publication_date
) => {
  return axios().patch("/ambassador/new-or-update-interview", {
    id,
    title,
    html_content,
    status,
    publication_date
  });
};

export default {
  currentMonthRank,
  recentAmbassadors,
  getOne,
  isInterviewExists,
  newOrUpdateInterview
};
