<template>
  <div v-if="user" class="presentation-block">
    <Modal :data="refuseButtonState" v-on:action="refuseRegistration" />
    <div class="flex flex-row justify-between items-center" id="user-preview">
      <div>
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          {{ user.first_name }} {{ user.last_name }}
        </h2>
        <h2 class="mb-6 text-sm font-semibold text-gray-700 dark:text-gray-200">
          @{{ user.username }}
        </h2>
      </div>
      <img
        class="object-cover w-full h-full rounded-full"
        :src="getProfilePicture(user.profile_picture)"
        alt=""
        loading="lazy"
      />
    </div>
    <div class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h3
        class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
      >
        Status de l'utilisateur
      </h3>
      <ul>
        <li>
          <p>Validé par un admin:</p>
          <span
            v-if="user.admin_validated === 1"
            class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
          >
            Validé
          </span>
          <span
            v-else-if="user.admin_validated === 0"
            class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:text-white dark:bg-orange-600"
          >
            En attente
          </span>
          <span
            v-else-if="user.admin_validated === 2"
            class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
          >
            Refusé
          </span>
        </li>
        <li>
          <p>Suspendu par un admin:</p>
          <span
            v-if="user.admin_suspended"
            class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
          >
            Oui
          </span>
          <span
            v-else
            class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
          >
            Non
          </span>
        </li>
        <li>
          <p>Compte suspendu:</p>
          <span
            v-if="user.disabled"
            class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
          >
            Oui
          </span>
          <span
            v-else
            class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
          >
            Non
          </span>
        </li>
        <li>
          <p>Inscrit via:</p>
          <span
            v-if="user.sign_up_from === 1"
            class="px-2 py-1 font-semibold leading-tight text-gray-700 bg-gray-100 rounded-full dark:text-gray-100 dark:bg-gray-700"
          >
            Appli
          </span>
          <span
            v-else-if="user.sign_up_from === 2"
            class="px-2 py-1 font-semibold leading-tight text-gray-700 bg-gray-100 rounded-full dark:text-gray-100 dark:bg-gray-700"
          >
            Admin
          </span>
          <span
            v-else
            class="px-2 py-1 font-semibold leading-tight text-gray-700 bg-gray-100 rounded-full dark:text-gray-100 dark:bg-gray-700"
          >
            Inconnu
          </span>
        </li>
        <li>
          <p>Compte actif:</p>
          <span
            v-if="user.password.account_activation_token"
            class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
          >
            Non
          </span>
          <span
            v-else
            class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
          >
            Oui
          </span>
        </li>
        <li>
          <p>Inscrit depuis le:</p>
          <span>{{ dateTransform(user.created_at) }}</span>
        </li>
      </ul>
      <h3
        class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
      >
        Données de l'utilisateur
      </h3>
      <ul>
        <li>
          <p>E-mail:</p>
          <span>{{ user.email.current_email }}</span>
        </li>
        <li>
          <p>E-mail validé:</p>
          <span
            v-if="user.email.validation_token"
            class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
          >
            Non
          </span>
          <span
            v-else
            class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
          >
            Oui
          </span>
        </li>
        <li>
          <p>Preuve d'activité professionelle:</p>
          <span v-if="user.sign_up_from === 2"
            >Inscription créée par un admin</span
          >
          <a
            v-else
            rel="noopener noreferrer"
            :href="user.proof_of_professional_activity"
            target="_blank"
            >{{ user.proof_of_professional_activity }}</a
          >
        </li>
        <li>
          <p>Description:</p>
          <span>{{ user.description }}</span>
        </li>
        <li>
          <p>URL externe:</p>
          <span>{{ user.external_url }}</span>
        </li>
        <li>
          <p>Date de naissance:</p>
          <span>{{ user.birth_date }}</span>
        </li>
        <li>
          <p>Expositions:</p>
          <span>{{ user.exhibition }}</span>
        </li>
        <li>
          <p>Formations:</p>
          <span>{{ user.formation }}</span>
        </li>
        <li>
          <p>Presse:</p>
          <span>{{ user.press }}</span>
        </li>
      </ul>
      <h3
        class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
        v-if="user.admin_validated === 0 || user.admin_validated === 1"
      >
        Actions
      </h3>
      <ul>
        <li v-if="user.admin_validated === 0">
          <button
            class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            @click="acceptRegistration"
          >
            Valider l'inscription
          </button>
          <button
            class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            @click="openRefuseRegistrationModal"
          >
            Refuser l'inscription
          </button>
        </li>
        <li v-if="user.admin_validated === 1">
          <button
            v-if="user.admin_suspended"
            class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            @click="changeSuspension(false)"
          >
            Annuler la suspension de l'utilisateur
          </button>
          <button
            v-else
            class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            @click="changeSuspension(true)"
          >
            Suspendre l'utilisateur
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import accounts from "@/utils/api/accounts";
import date from "@/utils/date";
import Modal from "@/components/Modal";

export default {
  components: { Modal },
  data() {
    return {
      user: null,
      refuseButtonState: {
        open: false,
        title: "Refus d'inscription",
        textareaMessage:
          "Raison (optionnel, permet à l'utilisateur de savoir pour quelles raisons son inscription est refusée via un e-mail)"
      }
    };
  },
  mounted() {
    // retrieve user data from route props
    if (this.$route.params.user) {
      this.$data.user = this.$route.params.user;

      // if doesn't exist, then call API
    } else {
      accounts
        .getOne(this.$route.params.id)
        .then(res => {
          this.$data.user = res.data;
        })
        .catch(() => this.$router.push({ name: "Accounts" }));
    }
  },
  methods: {
    getProfilePicture: accounts.getProfilePicture,
    dateTransform: date.format,
    acceptRegistration: function() {
      accounts
        .registrationValidation(this.$data.user.id, true, null)
        .then(() => {
          this.$data.user.admin_validated = 1;
        });
    },
    openRefuseRegistrationModal: function() {
      this.refuseButtonState.open = true;
    },
    refuseRegistration: function(reason) {
      accounts
        .registrationValidation(this.$data.user.id, false, reason)
        .then(() => {
          this.$data.user.admin_validated = 2;
          this.refuseButtonState.open = false;
        });
    },
    changeSuspension: function(suspend) {
      if (suspend) {
        accounts.suspend(this.$data.user.id).then(res => {
          this.$data.user.admin_suspended = res.data.data;
        });
      } else {
        accounts.cancelSuspension(this.$data.user.id).then(() => {
          this.$data.user.admin_suspended = null;
        });
      }
    }
  }
};
</script>
